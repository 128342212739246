<template>
    <div
        :class="isShowedSidebar ? 'sidebar-opened' : 'sidebar-closed'"
        class="editor-layout content-layout-wrapper"
    >
        <div class="content create-category__wrapper">
            <div class="create-category__header">
                <div class="create-category__header-info__block">
                    <button
                        class="create-category__header-info__block-back"
                        @click="$router.back()"
                    >
                        <arrow />
                        <p>BACK</p>
                    </button>
                </div>
                <slot name="header" />
            </div>
            <div class="create-category__body">
                <slot name="body" />
            </div>
        </div>
    </div>
</template>

<script>
import Arrow from '@/icons/Arrow';

export default {
    name: 'CreateCategoryLayout',
    components: {
        Arrow
    },
    data() {
        return {
            isShowedSidebar: true
        }
    },
    mounted() {
        this.$bus.$on('toggleSidebar', (val) => {
            this.isShowedSidebar = val
        })
    },
    beforeDestroy() {
        this.$bus.$off('toggleSidebar')
    }
}
</script>

<style scoped lang="scss">
    @import "@/assets/styles/dashboard/components/content/content-layout";
    .create-category__buttons {
        display: flex;
        margin-top: 15px;
        button {
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .create-category__wrapper {
        width: 660px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 !important;
        background: #fff;
        h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 23px;
            margin: 0;
        }
        .create-category__header, .create-category__body{
            padding: 30px 30px !important;
        }
        .create-category__header {
            border-bottom: 1px solid #D3D9E2;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }
        .create-category__body {

        }
    }
    .create-category__header-info__block {
        &-back {
            position: absolute;
            left: 30px;
            top: 50%;
            margin-top: -20px;
            background: none;
            border: none;
            display: flex;
            align-items: center;
            height: 40px;
            width: 80px;
            font-size: 16px;
            font-weight: bold;
            outline: none;
            cursor: pointer;
            transition: .1s;
            p {
                margin-top: 1px;
                margin-bottom: 0;
            }
            svg {
                margin-right: 10px;
                height: 15px;
            }
            &:hover {
                font-size: 18px;
                svg {
                    height: 16px;
                }
            }

        }
    }
</style>
