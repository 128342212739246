import {
    required,
    minLength
} from 'vuelidate/lib/validators';

export const validationSubRules = {
    category: {
        minLength: minLength(3),
        required
    },
    subcategories: {
        $each: {
            title: {
                required,
                async checkTitle(value) {
                    if (!value) return false
                    return new Promise((resolve) => {
                        const subcategories = this.subcategories.filter((item) => item.title === value)
                        if (subcategories.length > 1) {
                            resolve(false)
                        } else {
                            resolve(true)
                        }
                    })
                }
            }
        }
    }
}
