<template>
    <create-category-layout>
        <template #header>
            <h1>{{ titleText }} {{ mode === 'create' ? 'a new' : '' }} {{ type }}</h1>
        </template>
        <template #body>
            <new-category
                v-model="$v[fields[field_type].field].$model"
                :field.sync="$v[fields[field_type].field].$model"
                :errors="errorText || validationMessage($v[fields[field_type].field])"
                :is-valid="$v[fields[field_type].field].$dirty && !$v[fields[field_type].field].$anyError"
                :label="type || 'Category'"
                @input="onChange"
                @input.native="$v[fields[field_type].field].$touch()"
                @blur.native="$v[fields[field_type].field].$touch()"
            />
            <div class="create-category__buttons">
                <custom-button
                    v-show="showButton || mode === 'create'"
                    default
                    type="submit"
                    class="blue-button"
                    @on-btn-click="validate"
                >
                    {{ titleText }}
                </custom-button>
            </div>
            <template v-if="subcategory">
                <div class="subcategories">
                    <div class="subcategories-header">
                        <span>Subcategories</span>
                        <div class="subcategories-counter">
                            {{ subcategories.length }}
                        </div>
                    </div>
                    <new-sub-category
                        v-for="(item, id) in $v.subcategories.$each.$iter"
                        :id="id"
                        :key="id"
                        v-model="item.title.$model"
                        :category="subcategories[id]"
                        :field.sync="item.title.$model"
                        :errors="errorText || validationMessage(item.title)"
                        :is-valid="item.title.$dirty && !item.title.$anyError"
                        @input.native="item.title.$touch()"
                        @blur.native="item.title.$touch()"
                        @deleteSubCategory="deleteSubCategory($event)"
                        @saveSubCategory="saveSubCategory($event)"
                        @updateSubCategory="updateSubCategory($event)"
                    />
                    <button
                        class="create-new"
                        @click="addSubCategory"
                    >
                        <div class="create-new__circle">
                            +
                        </div>
                        CREATE NEW
                    </button>
                </div>
            </template>
        </template>
    </create-category-layout>
</template>

<script>

import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationCategoryRules } from '@/validation/CategoryModal/Rules'
import { validationSubRules } from '@/validation/CategoryModal/SubRules'
import { validationRoleRules } from '@/validation/CategoryModal/RoleRules'
import { validationPermissionRules } from '@/validation/CategoryModal/PermissionsRules'
import { formMessages } from '@/validation/CategoryModal/Messages'

import CreateCategoryLayout from '@/layouts/CreateCategoryLayout';
import NewCategory from '@/components/create-category/components/NewCategory';
import NewSubCategory from '@/components/create-category/components/NewSubCategory';

export default {
    name: 'CreateNewCategory',
    components: {
        CreateCategoryLayout,
        NewCategory,
        NewSubCategory
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations() {
        if (this.subcategory) {
            return { ...validationSubRules }
        }
        return { ...this.fields[this.field_type].validator }
    },
    props: {
        subcategory: {
            type: Boolean,
            default: false
        },
        contentType: {
            type: String
        },
        mode: {
            type: String,
            default: 'create'
        },
        type: {
            type: String,
            default: 'Category'
        },
        field_type: {
            type: String,
            default: 'category'
        },
        mainCategory: Object,
        errorText: {
            type: String,
            default: ''
        },
        errorsSubCategories: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            category: '',
            default_category_name: '',
            permission: '',
            role: '',
            subcategories: [],
            showButton: false,
            fields: {
                permission: {
                    field: 'permission',
                    validator: validationPermissionRules
                },
                role: {
                    field: 'role',
                    validator: validationRoleRules
                },
                category: {
                    field: 'category',
                    validator: validationCategoryRules
                }
            }
        }
    },
    computed: {
        titleText() {
            return this.mode === 'create' ? 'Create' : 'Update'
        }
    },
    watch: {
        async mainCategory(val) {
            if (val) {
                this[this.fields[this.field_type].field] = await val.category_group[0].title
                this.showButton = await false
                this.subcategories = val.categories
                if (!await val.categories.length) {
                    this.subcategories.push({
                        title: '',
                        posts_count: 0
                    })
                }
            }
        },
        category(val) {
            if (!this.showButton) this.default_category_name = val
            if (val === this.default_category_name) this.showButton = false
        }
    },
    activated() {
        this.$bus.$on('hideCategoryGroupButton', () => {
            this.showButton = false
        })
    },
    deactivated() {
        this[this.fields[this.field_type].field] = ''
        this.$bus.$off('hideCategoryGroupButton')
        this.$v.$reset();
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        submit() {
            this.$emit('on-save-btn-click', {
                name: this[this.fields[this.field_type].field]
            })
            this.default_category_name = this[this.fields[this.field_type].field]
        },
        back() {
            this.$router.back()
        },
        async addSubCategory() {
            const isValid = await this.isValid()
            if (isValid) {
                this.subcategories.push({
                    title: '',
                    posts_count: 0,
                    group_id: this.mainCategory.category_group[0].id,
                    position_id: this.subcategories.length
                })
            }
        },
        deleteSubCategory(event) {
            this.$emit('deleteSubCategory', event)
        },
        async saveSubCategory(event) {
            const isValid = await this.isValid()
            if (isValid) {
                this.$emit('saveSubCategory', event)
            }
        },
        async updateSubCategory(event) {
            const isValid = await this.isValid()
            if (isValid) {
                this.$emit('updateSubCategory', event)
            }
        },
        reset() {
            this[this.fields[this.field_type].field] = ''
            this.subcategories = []
        },
        onChange() {
            this.showButton = true
            this.$emit('input')
        }
    }
}
</script>

<style lang="scss">
    .category-input__block {
        margin-top: 12px;
    }
    .add-sub {

    }
    .subcategories {
        margin-top: 10px;
        padding-bottom: 20px;
        // border-bottom: 1px solid #D3D9E2;
        .create-new {
            color: #1F2939;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            border: none;
            background: #fff;
            margin-top: 36px;
            .create-new__circle {
                width: 20px;
                height: 20px;
                font-size: 20px;
                font-weight: 100;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                border-radius: 50%;
                border:1px solid #4F4F4F;
            }
        }
        &-header {
            display: flex;
            align-items: center;
            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #1f2939;
            }
        }
        &-counter {
            background-color: rgba(79,79,79,.1);
            border-radius: 4px;
            padding: 5px 10px;
            margin-left: 10px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #1f2939;
        }
    }
</style>
